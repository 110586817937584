import {
    HashRouter,
    Routes,
    Route,
} from "react-router-dom";
import React from "react";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";
import { GAPageViews } from "../google-analytics/google.analytics";
import Login from "../pages/auth/login";
import ModuleDetail from "../pages/home/modules-detail";
import Work from "../pages/work/work";
import Team from "../pages/teams/team";
import Career from "../pages/career/career";
import CareerDetail from "../pages/career/career-detail";
import Company from "../pages/company/company";
import Blogs from "../pages/blogs/blogs";
import ReadBlog from "../pages/blogs/read-blog";
import WorkDetailPage from "../pages/work/work-detail-page";
import Contact from "../pages/contact/contact";
import PageNotFound from "../pages/pageNotFound";
import IndexPage from "../pages/home";
import RequireAuth from "./AuthRoutes";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
const history = createBrowserHistory();

// Listen for changes to the current location
const hashPath = window.location

history.listen(location => {
    window.scrollTo(0, 0)
    GAPageViews({ hitType: "pageview", page: hashPath.hash + hashPath.search || '/', title: hashPath.hash + hashPath.search })
})


class AppRoutes extends React.Component {
    state = {
        auth: null,
    }


    async componentDidMount() {
        const auth = await this.getAuth();
        this.setState({ auth });
        GAPageViews({ hitType: "pageview", page: hashPath.hash + hashPath.search || '/', title: hashPath.hash + hashPath.search })

    }
    render() {
        return (
            <HashRouter {...this.props}>
                <Routes>
                    <Route exact path="*" element={
                        <PageNotFound />
                    } />
                    <Route exact path="/" element={
                        <IndexPage />
                    } />
                    <Route exact path="/work/:slug" element={  //module detail page(home detail)
                        <ModuleDetail />
                    } />


                    <Route exact path="/work" element={
                        <Work />
                    } />
                    <Route exact path="/detail/:slug" element={ //work detail page
                        <WorkDetailPage />
                    } />

                    <Route exact path="/team" element={
                        <Team />
                    } />
                    <Route exact path="/career" element={
                        <Career />
                    } />
                    <Route exact path="/career-detail/:slug" element={
                        <CareerDetail />
                    } />
                    <Route exact path="/company" element={
                        <Company />
                    } />
                    <Route exact path="/blog" element={
                        <Blogs />
                    } />
                    <Route exact path="/blog/:category" element={
                        <Blogs />
                    } />
                    <Route exact path="/read-blog/:slug" element={
                        // <RequireAuth>
                            <ReadBlog />
                        // </RequireAuth>
                    } />
                    <Route exact path="/contact" element={
                        <Contact />
                    } />
                    <Route exact path="/login" element={
                        <Login />
                    } />
                </Routes>
            </HashRouter>
        );
    }
}

export default AppRoutes;