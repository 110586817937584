import React, { useEffect, useState } from 'react'
import '../../assets/style/scss/main-blog.blogs-2.css' //this
// import '../assets/style/scss'
import { useDispatch } from 'react-redux'
import { fetchBlogs, fetchBlogsByCategory } from '../../redux/slices/blogsAPISlice'
import { Spinner } from 'react-bootstrap'
import BlogCardSection from '../../components/Blogs/blog-card-section'
import BlogCategorySection from '../../components/Blogs/blog-category-section'
import FeaturedBlogSection from '../../components/Blogs/featured-blogs'
import { colorConfig } from '../../constants/websiteConfigration.constants'
import { useParams } from 'react-router-dom'
import Header from '../../components/common/headerSection'
import { BlogPageHeader } from '../../constants/header.constants'
import BlogFooterSection from '../../components/Blogs/blog-footer-section'
import BlogNavbar from '../../components/Blogs/blog-navbar'
import { verifyJWTToken } from '../../redux/atuhSlices/AuthAPI'

function Blogs() {
  const { category } = useParams()
  const [featuredBlogs, setFeaturedBlogs] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [blogs, setBlogs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [categoriesLength, setCategoriesLength] = useState(0);
  const dispatch = useDispatch()
  useEffect(() => {
    const sys = window.speechSynthesis
    if (sys) {
      sys.cancel()
    }
    getBlogs()
  }, [category])

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(verifyJWTToken({ token }));
    }

  }, []);


  const getBlogs = async () => {
    setIsLoading(true)
    if (!category) {
      const response = await dispatch(fetchBlogs())
      if (response?.payload?.data?.status === 200) {
        const data = response.payload.data.data
        const featuredFilter = data.filter((item) => item.featured === true)
        setBlogs(data)
        setFeaturedBlogs(featuredFilter)
      }
      if (response?.payload?.data?.error) {
        console.log("Error", response?.payload?.data?.message)
      }
    } else {
      const response = await dispatch(fetchBlogsByCategory(category))
      if (response?.payload?.data?.status === 200) {
        const data = response.payload.data.data
        setBlogs(data)
      }
      if (response?.payload?.data?.error) {
        setBlogs([])
        console.log("Error", response?.payload?.data?.message)
      }
    }
    setIsLoading(false)
  }

  const handleSearchCallBack = (searchResponse) => {
    if (!searchResponse) {
      setIsSearching(false)
      getBlogs()
    } else {
      setIsSearching(true)
      setIsLoading(true)
      if (searchResponse?.status === 200) {
        setBlogs(searchResponse?.data)
      }
      if (searchResponse?.error) {
        setBlogs([])
        console.log("Error", searchResponse)
      }
      setIsLoading(false)
    }

  }

  const handleCategoriesLength = (length) => {
    setCategoriesLength(length);
  };

  return (
    <div className={`${colorConfig?.secondaryColor}`} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div className="_a _b">
        <div className="_d _e _f _g _h _i _j _k"></div>
        {/* Blog navigationbar */}
        <BlogNavbar showSearch={true} parentCallBack={handleSearchCallBack} />
        {/*--- */}
        <Header props={BlogPageHeader} />
        <div className="_n _cx">
          {/* SHOW FEATURED BLOGS */}
          <div className="_jq _ah">
            <div className="_n _p">
              <div className="_at _au _av _aw _ax _ay _az _al">
                {/* <div className="_ic _ah" style={{ paddingTop: '97px' }}> */}
                {/* <div className="_ic _ah" style={{ paddingTop: '16px' }}> */}
                {!category && !isSearching &&
                  <div className="_al _ah">
                    <div className=" _fh _n _bc _fi _fj _fk _fl _fm _fn _fo _fp _fq _fr _fs _ft _fu _fv _fw _fx">
                      {/* SHOW FEATURED BLOGS */}
                      <FeaturedBlogSection maxToShow={4} />
                      {/* LOOP END */}
                    </div>
                  </div>
                }
                {/* </div> */}
              </div>
            </div>
          </div>
          {/*    {/* SIDEBAR FOR SMALL SCREEN */}
          {!isSearching &&
            <div className="_ah _hm _hn _ho">
              <div className="_n _p">
                <div className="_at _au _av _aw _ax _ay _az _al">
                  <div className="_hp _hq _hr _i _d">
                    {/* SECTION DIVIDED FROM HERE */}
                    <div className="_hs _ht _hu _hv _hw _hx _hy _hz _ia _ib _ah">
                      {/* {blogCategories.length > 0 && */}
                      <div className="_ah">
                        {categoriesLength > 0 &&
                          <div className="_ic _ah">
                            <h2 className="_by _fy _et _ca _id _cb">Discover more of what matters to
                              you</h2>
                          </div>
                        }
                        <div className="_n _fh _fi">
                          <BlogCategorySection onCategoriesLengthChange={handleCategoriesLength} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="_ew _ah _im _in _io">
            <div className="_n _p">
              <div className="_at _au _av _aw _ax _ay _az _al">
                <div className="_s _t _u _v _w _x">
                  <section className="_pw-homefeed _y _z _ab _ac">
                    <div className="_ah">
                      <div>
                        <div className="_kv _kw _ah">
                          {/* BLOGS LIST CARD [DEFAULT CLASSNAME-> _al cw] */}
                          {isLoading ?
                            < Spinner variant='white' animation="border" role="status" className=' d-flex m-auto' >
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            : <BlogCardSection data={blogs} />
                          }
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* SIDEBAR FOR LARGE SCREEN */}
                  <aside className="_f _ae _z _af _ac">
                    <div className="_ah _ip _iq">
                      <div className="_h _k _j _ir _is">
                        {/* SECTION DIVIDED FROM HERE */}
                        <div className="_hs _ht _hu _hv _hw _hx _hy _hz _ia _ib _ah">
                          {/* {blogCategories.length > 0 && */}
                          <div className="_ah">
                            {categoriesLength > 0 &&
                              <div className="_ic _ah">
                                <h2 className="_by _fy _et _ca _id _cb">
                                  Discover more of what matters to you</h2>
                              </div>
                            }
                            <div className="_n _fh _fi">
                              <BlogCategorySection onCategoriesLengthChange={handleCategoriesLength} />
                            </div>
                          </div>
                          {/* } */}
                        </div>
                      </div>
                      <div className="_it _n _bc _fi">
                        <div className="_iu _iv _ah"><a
                          className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br"
                          rel="noopener follow"
                          href="#company">
                          <p className="_by _b _bz _ca _iw">About</p>
                        </a></div>
                        <div className="_iu _iv _ah"><a
                          className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br"
                          rel="noopener follow"
                          href="#career">
                          <p className="_by _b _bz _ca _iw">Career</p>
                        </a></div>
                        <div className="_iu _iv _ah"><a
                          className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br"
                          href="#blog"
                          rel="noopener follow">
                          <p className="_by _b _bz _ca _iw">Blog</p>
                        </a></div>
                        <div className="_iu _ah"><a className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br"
                          rel="noopener follow"
                          href="#team">
                          <p className="_by _b _bz _ca _iw">Team</p>
                        </a></div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      <BlogFooterSection />
    </div >
  )
}

export default Blogs