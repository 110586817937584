import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/common/navigation-bar'
import SectionHero from './section-hero'
import SectionTrainingModule from './section-training-module'
import '../../assets/style/section-hero.css'
import '../../assets/style/main.css'
import '../../assets/style/scss/main-aura.css'
import SectionFooter from '../../components/common/section-footer'
import { useDispatch, } from 'react-redux'
import { fetchWebsiteSettings } from '../../redux/slices/websiteSettingsSlice'
import ScrollTopBtn from '../../ui-components/ui-common/scrollTopBtn'
import Header from '../../components/common/headerSection'
import { HomePageHeader } from '../../constants/header.constants'
import { logoutAction } from '../../redux/atuhSlices/AuthAPI'

function IndexPage() {
    const dispatch = useDispatch()
    // const settingState = useSelector((state) => state.websiteSettings)
    const [releasGilligDanda, setReleaseGilliDanda] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const showHeadLine = document.getElementById("showHeadline")
    const navbar = document.getElementById("top-navbar")

    const handleScroll = () => {
        if (releasGilligDanda) {
            if (showHeadLine.classList.contains("show") && window.scrollY < 23) {
                navbar.classList.add("showHeadline")
            } else {
                navbar.classList.remove("showHeadline")
            }
        }
    }
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const hasToken = queryParams.get('token');
        if (hasToken) {
            localStorage.setItem("access_token",hasToken);
            window.location.href = `${process.env.REACT_APP_BASE_URL_MAIN_SITE_IP}/#blog`;
            // window.location.href = 'http://localhost:3001/#blog';
        }
    }, [])

    //Remove token when user logout from the admin site
    // useEffect(() => {
    //     const ReceiveLogoutCallBackFromAdminSiteLogout = (event) => {
    //         if (event.origin !== process.env.REACT_APP_BASE_URL_ADMIN_IP) {
    //             console.warn('Unexpected origin:', event.origin);
    //             return;
    //         }

    //         const { data } = event;
    //         if (data && data.token) {
    //             console.log("Removed token", data.token);
    //             localStorage.removeItem('access_token')
    //             dispatch(logoutAction());
    //         }
    //     };

    //     window.addEventListener('message', ReceiveLogoutCallBackFromAdminSiteLogout);

    //     // Clean up the event listener
    //     return () => {
    //         window.removeEventListener('message', ReceiveLogoutCallBackFromAdminSiteLogout);
    //     };
    // }, []);

    useEffect(() => {
        if (releasGilligDanda) {

            if (navbar) {
                navbar.classList.add("showHeadline")
            }
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [showHeadLine, navbar])

    useEffect(() => {
        getWebsiteSettings()
    }, [])

    const getWebsiteSettings = async () => {
        setIsLoading(true)
        const response = await dispatch(fetchWebsiteSettings())
        if (response.payload?.data?.error) {
            console.log("Error", response.payload.data.message)
        }
        setIsLoading(false)
    }
    return (
        <>
            {releasGilligDanda &&
                <div id="showHeadline" className="show text-white text-center align-items-center" style={{ backgroundColor: '#dc3545', fontSize: '0.8rem', padding: '2px 0', zIndex: '9999', position: 'sticky' }}>
                    <a href='https://bit.ly/GilliDandaVRApp' target='_blank' rel="noreferrer">Gilli Danda VR out now. Download now on Meta Quest App Lab</a> - <a href='https://discord.gg/tjx8jFk3u6' target='_blank' rel="noreferrer"> To know more join our Discord community</a>
                </div>
            }
            <NavigationBar />
            <Header props={HomePageHeader}
            />
            <ScrollTopBtn />
            <SectionHero />
            <SectionTrainingModule />
            <SectionFooter />
        </>
    )
}



export default IndexPage