import React, { useEffect, useState } from 'react'
import SectionJoinOurTeam from '../../components/section-join-our-team'
import SectionFooter from '../../components/common/section-footer'
import NavigationBar from '../../components/common/navigation-bar'
import '../../assets/style/team.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTeamList } from '../../redux/slices/teamSlice'
import { FaLinkedin } from "react-icons/fa";

import parse from 'html-react-parser';
import { Spinner } from 'react-bootstrap'
import Header from '../../components/common/headerSection'
import { TeamPageHeader } from '../../constants/header.constants'
import { colorConfig } from '../../constants/websiteConfigration.constants'
function Team() {
    const [data, setData] = useState([])
    const [stringToHtml, setStringToHtml] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const settingState = useSelector((state) => state.websiteSettings)
    const [teamData, setTeamData] = useState({});

    useEffect(() => {
        try {
            if (!settingState.loading) {
                setTeamData(settingState?.websiteSettings?.teamPage)
                setStringToHtml(settingState?.websiteSettings?.teamPage?.mainTitle)
            }
        } catch (error) {
            console.log("Error", error);
        }
    }, [settingState])

    useEffect(() => {
        getList()
    }, [])

    const getList = async () => {
        setIsLoading(true)
        try {
            const resp = await dispatch(fetchTeamList())
            if (resp.payload?.data?.status === 200) {
                setData(resp.payload.data.data)
            } else if (resp.payload?.data?.error) {
                console.log("Error", resp.payload.data.message);
            }
        } catch (error) {
            console.log("Error", error);
        }
         setIsLoading(false)
    }

    return (
        <div>
            <NavigationBar />
            <Header props={TeamPageHeader}/>
            <main>
                <div className={`statements ${colorConfig.secondaryColor} text-center mx-auto bg-design-br`}>
                    <h1 className="title col-8 position-absolute top-50 start-50 translate-middle" id='get-html'>
                        {parse(stringToHtml)}
                    </h1>
                    {teamData && teamData.statements
                        ?
                        teamData.statements.map((value, index) => (
                            <p className={value.className ? value.className : 'top-center'} key={index}><span >{value.subTitle}</span>
                                {value.excerpt}
                            </p>
                        )) : ''}
                </div>
                {isLoading ?
                    <Spinner animation="border" role="status" className='m-auto d-flex'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    <div className={`team py-5 ${colorConfig.secondaryColor}`}>
                        <div className="container">
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-5">
                                {data && data.team
                                    ?
                                    data.team.map((value, index) => (
                                        <div className="col" key={index}>
                                            <div className={`card bg-dark-blue-acent text-light ${value.bgColorClass ? value.bgColorClass : 'default-bg'} overflow-hidden`}>
                                                <div className="card-body text-center">
                                                    <h5 className="card-title mb-2">{value.name}</h5>
                                                    <p className="mb-0">{value.designation}</p>
                                                    <img
                                                        srcSet={`${process.env.REACT_APP_IMAGE_URL}/_uploads/team/${value.picture} 1x, ${process.env.REACT_APP_IMAGE_URL}/_uploads/team/${value.picture} 2x`}
                                                        sizes="(max-width: 600px) 100vw, (max-width: 960px) 33vw, 30vw"
                                                        src={`${process.env.REACT_APP_IMAGE_URL}/_uploads/team/${value.picture}`}
                                                        className="card-img-top"
                                                        alt="arrow"
                                                        loading="lazy"
                                                    />
                                                    <ul className="nav mt-3">
                                                        <li className="nav-item">
                                                            <a className="nav-link" target='_blank' href={value.linkedInUrl} rel="noreferrer"><FaLinkedin /></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : <h1 className=' text-center justify-content-center d-flex m-auto'>No data found...</h1>}
                            </div>
                        </div>
                    </div>
                }
            </main>
            <SectionJoinOurTeam />
            <SectionFooter />
        </div>
    )
}

export default Team