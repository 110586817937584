import React, { useEffect, useState } from 'react';
import '../../assets/js/blog-navbar';
import { colorConfig, websiteConfig } from '../../constants/websiteConfigration.constants';
import { HiArrowUpRight, HiHome, HiOutlinePencilSquare } from 'react-icons/hi2';
import { IoCloseSharp, IoSearch } from 'react-icons/io5';
import { searchBlog } from '../../redux/slices/blogsAPISlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { logoutAction } from '../../redux/atuhSlices/AuthAPI';

function BlogNavbar({ showSearch, parentCallBack, showHomeIcon }) {
    const [search, setSearch] = useState();
    const [enableSearch, setEnableSearch] = useState(false);
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOnSearch = () => {
        setSearch(undefined);
        setEnableSearch(!enableSearch);
    };

    const handleSearchBlog = async () => {
        try {
            const searchResp = await dispatch(searchBlog({ title: search }));
            parentCallBack(searchResp?.payload?.data);
        } catch (error) {
            parentCallBack(error);
            console.log('Error', error);
        }
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            handleSearchBlog();
        }, 1000);
        return () => clearTimeout(getData);
    }, [search]);

    const handleLogout = async () => {
        // removeTokenFromAdminSite();
        // setTimeout(() => {
        dispatch(logoutAction());
        // navigate('/#blog');
        // }, 1000);
    };
    const handleLoginClick = () => {
        const redirectUrl = encodeURIComponent(`${process.env.REACT_APP_BASE_URL_MAIN_SITE_IP}/`);
        const adminLoginUrl = `${process.env.REACT_APP_BASE_URL_ADMIN_IP}/pages/login?redirect=${redirectUrl}`;
        window.location.href = adminLoginUrl;
    };
    return (
        <div className={`l m n o ${colorConfig?.primaryColor}`} id='top-navbar'>
            <div className="p q ab ac">
                <div className="ab q ae">
                    {!enableSearch &&
                        <>
                            <a className="af ag ah ai aj ak al am an ao ap aq ar as at ab"
                                aria-label="Homepage" data-testid="headerMediumLogo"
                                href="#"
                                rel="noopener follow">
                                <img className="ms-3 me-3" src={websiteConfig?.mainLogo} alt="not found" style={{ width: '90px' }} />
                            </a>
                            {showHomeIcon &&
                                <a className="af ag ah ai aj ak al am an ao ap aq ar as at ab text-white"
                                    aria-label="Homepage" data-testid="headerMediumLogo"
                                    href="#blog"
                                    rel="noopener follow">
                                    <HiHome style={{ width: '60px', height: '30.92px', color: 'white' }} />
                                </a>
                            }
                        </>
                    }
                    {showSearch &&
                        <div className="aw h">
                            <div className="ab ax ay az ba q bb bc">
                                <div className="bl" aria-hidden="false" aria-describedby="searchResults"
                                    aria-labelledby="searchResults">
                                </div>
                                <div className="bm bn ab">
                                    <IoSearch style={{ width: "24px", height: "24px", color: "white" }} />
                                </div>
                                <input type='text' onChange={(e) => setSearch(e.target.value)} id='search-input'
                                    className="ax bd be bf z bg bh bi bj bk text-white" placeholder="Search" style={{ color: "white", borderColor: "white" }} />
                            </div>
                        </div>
                    }
                </div>
                {localStorage.getItem('access_token') && user && user?.role !== 'user' &&
                    <div className="h k w ff fg d-md-block d-sm-none d-lg-block">
                        <div className="fh ab">
                            <span>
                                <a className="af ag ah ai aj ak al am an ao ap aq ar as at" data-testid="headerWriteButton"
                                    href={`${process.env.REACT_APP_BASE_URL_ADMIN_IP}/blogs/create-blog`} target='_blank' rel="noopener follow">
                                    <div className="be b bf z dt fi fj ab q fk fl">
                                        <HiOutlinePencilSquare style={{ width: "24px", height: "24px", color: 'white' }} />
                                        <div className="ds l text-white">
                                            Write
                                        </div>
                                    </div>
                                </a>
                            </span>
                        </div>
                    </div>
                }
                {showSearch &&
                    <div className="k j i d">
                        <div className="fh ab">
                            <span className="af ag ah ai aj ak al am an ao ap aq ar as at"
                                data-testid="headerSearchButton"
                                href="#"
                                rel="noopener follow">
                                <div className="be b bf z dt fi fj ab q fk fl">
                                    {enableSearch &&
                                        <div className="">
                                            <div className="ab ax ay az ba q bb bc">
                                                <div className="bl" aria-hidden="false" aria-describedby="searchResults"
                                                    aria-labelledby="searchResults">
                                                </div>
                                                <div className="bm bn ab">
                                                    <IoSearch style={{ width: "24px", height: "24px", color: "white" }} />
                                                </div>
                                                <input type='text' onChange={(e) => setSearch(e.target.value)} id='search-input'
                                                    className="ax bd be bf z bg bh bi bj bk text-white" placeholder="Search" style={{ color: "white", borderColor: "white" }} />
                                            </div>
                                        </div>
                                    }
                                    {enableSearch ? <IoCloseSharp style={{ width: "24px", height: "24px", marginLeft: '5px', color: "white" }} onClick={() => handleOnSearch()} /> :
                                        <IoSearch style={{ width: "24px", height: "24px", color: "white" }} onClick={() => handleOnSearch()} />
                                    }
                                </div>
                            </span>
                        </div>
                    </div>
                }
                <div className="l" aria-hidden="false">
                    <Dropdown>
                        <Dropdown.Toggle variant='default' className='border-0 hide-dropdown' id="dropdown-basic">
                            {localStorage.getItem('access_token') && user && user.profile_pic ?
                                <div className="l fi">
                                    <img alt="" className="l fc bx by bz cw" src={`${process.env.REACT_APP_IMAGE_URL_S3}${user?.profile_pic}`} width="32" height="32" loading="lazy" role="presentation" />
                                    <div className="fq bx l by bz fr n ax fs">
                                    </div>
                                </div>
                                :
                                <div className="l fi">
                                    <img alt="" className="l fc bx by bz cw" src={require('../../assets/images/user-image.jpg')} width="32" height="32" loading="lazy" role="presentation" />
                                    <div className="fq bx l by bz fr n ax fs">
                                    </div>
                                </div>
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant='dark'>
                            {localStorage.getItem('access_token') ?
                                <Dropdown.Item 
                                // href={`#blog`}
                                 onClick={() => handleLogout()} className='d-sm-flex'>
                                    Logout
                                </Dropdown.Item>
                                :
                                <Dropdown.Item onClick={()=>handleLoginClick()} className='d-sm-flex'>
                                    Sign in
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default BlogNavbar;
